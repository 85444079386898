import React from 'react';
import { NavLink } from 'react-router-dom';

import { Fonts } from '../theme/fonts';
import { Containers } from './../theme/containers';
import { Styling } from '../theme/styling';
import { Images } from '../../assets/img';
import Social from '../social';

export const Footer = () => {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <>
            <div
                className={`relative flex items-center justify-center mt-10 2xsm:mb-0 md:mb-6 lg:mb-0 2xsm:z-0 lg:z-20`}>
                <div
                    className={`${Styling.transitionStyleFast} ${Containers.mainContainer}`}>
                    <footer
                        className={`bg-primary dark:bg-darkerPrimary 2xsm:rounded-none md:rounded-lg lg:rounded-b-none lg:rounded-t-lg 2xsm:px-8 2xsm:pt-10 md:px-14 md:pt-10 md:pb-2 lg:px-16 lg:pt-12 4xl:pb-5`}>
                        <div
                            className="w-full flex 2xsm:flex-col md:flex-row items-center 2xsm:justify-center md:justify-start footerTxt">
                            <div
                                className='flex 2xsm:flex-col md:flex-col w-full items-center 2xsm:justify-center md:justify-start md:border-r-2 border-secondary border-opacity-50 dark:border-opacity-30'>
                                <div className="w-auto md:w-full">
                                    <img
                                        src={Images.logoTxt}
                                        alt="ResolYou Kenya"
                                        className='2xsm:w-32 2xl:w-36 2xsm:mb-2 xl:mb-3'
                                    />
                                </div>
                                <Social />
                                <div className={`w-full text-white/80 2xsm:text-center md:text-left tracking-[.05em]Plus 2xsm:leading-3 lg:leading-4 opacity-90 ${Fonts.footerCopy}`}>
                                    <p
                                        className={``}>
                                        Copyright &copy; {getCurrentYear()}, Resolyou,
                                    </p>
                                    <p className='md:-mt-0.5'>All Rights Reserved. </p>
                                </div>
                            </div>
                            <div
                                className="w-full text-right">
                                <div
                                    className="w-full">
                                    <p
                                        className={`
                                        ${Fonts.footerAddress}
                                        opacity-90 
                                        2xsm:border-t border-secondary/10 md:border-none
                                        
                                    `}>
                                        {/* <span className='text-lightSecondary 2xsm:text-[0.85rem] lg:text-[0.75rem] 2xl:text-[0.85rem]'>Resolyou</span> */}
                                        <br />
                                        Chariot Management
                                        <br />
                                        8<sup>th</sup> Floor. The Address
                                        <br />
                                        Muthangari Drive, Westlands
                                        <br />
                                        <a
                                            className='transition-all duration-300 underline decoration-lightSecondary hover:decoration-blue-600 underline-offset-[0.55rem] text-lightSecondary hover:text-blue-600'
                                            href="tel:+254739700969"
                                        >
                                            Tel: +254739700969
                                        </a>
                                        <div className="mt-3 opacity-80">
                                            <p className={`md:mt-2 ${Fonts.footerLocation}`}>
                                                Nairobi,
                                            </p>
                                            <p className={`xsm:-mt-[0.65rem] xl:-mt-[0.25rem] 2xl:-mt-[0.45rem] ${Fonts.footerLocation}`}>
                                                Kenya.
                                            </p>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`
                            ${Fonts.footerFont}
                            footerLinks flex 2xsm:flex-col md:flex-row text-lightSecondary dark:text-secondary 2xsm:tracking-wide md:tracking-wider w-full md:pt-5 2xsm:mt-4 md:mt-10 border-t border-lightSecondary dark:border-secondary border-opacity-30 dark:border-opacity-20 
                            `}
                        >
                            <a
                                href="https://linchpin.co.ke/"
                                target="_blank"
                                rel="noreferrer"
                                className="flex 2xsm:flex-col md:flex-row 2xsm:items-center 2xsm:w-full md:w-1/2 2xsm:text-center md:text-left 2xsm:pt-3 2xsm:pb-4 md:p-0 md:pb-0 border-secondary border-opacity-20 2xsm:border-b md:border-none"
                            >
                                <img
                                    alt="Linchpin Ltd Kenya"
                                    src={Images.linchpin}
                                    className='2xsm:block md:hidden 2xsm:my-2 xl:mt-0.5 2xsm:w-6 md:w-5 xl:w-4'
                                />
                                <p
                                    className={`transition-all duration-300 hover:text-white 2xsm:pb-2`}
                                >
                                    Designed By Linchpin
                                </p>
                                <span>
                                    <img
                                        alt="Linchpin Ltd Kenya"
                                        src={Images.linchpin}
                                        className='2xsm:hidden md:block 2xsm:ml-1 xl:ml-2 2xsm:mt-0 md:-mt-2 xl:-mt-2.5 2xsm:w-3 xl:w-3.5'
                                    />
                                </span>
                            </a>
                            <ul
                                className="2xsm:w-full md:w-1/2 flex flex-row 2xsm:justify-center 2xsm:py-4 md:py-0 md:justify-end 2xsm:space-x-2 xl:space-x-6 2xl:space-x-10"
                            >
                                <li>
                                    <NavLink
                                        to="/"
                                        className='transition-all duration-300 hover:text-white'
                                    >
                                        Terms &amp; Conditions
                                    </NavLink>
                                </li>
                                <li>|</li>
                                <li>
                                    <NavLink
                                        to="/"
                                        className='transition-all duration-300 hover:text-white'
                                    >
                                        Privacy
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </footer>
                </div>
            </div>
        </>
    )
}