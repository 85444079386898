import React, { useState } from "react";
import { BsFillCaretDownFill } from "react-icons/bs"
import { Fonts } from '../theme/fonts';
import { Styling } from "../theme/styling";
// import "";

const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className={`${Fonts.bodyFont}  whitespace-pre-wrap`}>
            {isReadMore ? text.slice(0, 0) : text}
            <br />
            <button
                onClick={toggleReadMore}
                className={`
                ${Styling.transitionStyleFastest} 
                relative flex items-center w-auto cursor-pointer rounded-[2px] xsm:mt-3 2xl:mt-3.5 py-[0.5rem] px-10 hover:bg-darkPrimary hover:text-sky-200 dark:hover:bg-lightishSecondary dark:text-sky-500 dark:hover:text-white border border-blue-500 dark:border-sky-700/50 dark:hover:border-sky-300
                `}>
                <p className="-ml-1">
                    {isReadMore ? `Read More` : `Show Less`}
                </p>
                <BsFillCaretDownFill className={`absolute w-2.5 right-[1.25rem] ${Styling.transitionStyleFast} ${isReadMore ? 'mt-0.5 rotate-0' : 'rotate-[540deg]'}`} />
            </button>
        </p>
    );
};

const Content = ({ slideInfo }) => {
    return (
        <div className="container">
            {/* <h2
                className={`${Fonts.sectionTitleFont}`}
            >
                {slideTitle}
            </h2> */}
            <ReadMore>
                {slideInfo}
            </ReadMore>
        </div>
    );
};

export default Content;
