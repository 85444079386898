export const Spacing = {
    menuSpace:
        'md:px-2 lg:px-2 xl:px-5 2xl:px-3 2xlPlus:px-8 py-2'
    ,

    menuItemBorder:
        'h-full w-2 border-r border-primary dark:border-secondary border-opacity-30 dark:border-opacity-20'
    ,

    /* menuItemsSpace:
        'md:space-x-14 lg:space-x-16 2xl:space-x-20 2xlPlus:space-x-16'
    , */

    infoPadding:
        `   2xsm:px-14 2xsm:pb-12 md:px-24 lg:px-32 lg:py-14 xl:px-16
        `
    ,
    profilePadding:
        `   
            2xsm:px-10 2xsm:py-4 lg:py-14 xl:pr-0 xl:pl-16 
        `
    ,

    listStyling:
        `
            2xsm:my-2 lg:my-3 xl:my-4
        `
    // footerPadding:
    //     ''
    // ,

}
