import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { BarLoader } from 'react-spinners';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ThemeContext } from '../../components/darkmode/themeContext';

import { Fonts } from '../../components/theme/fonts';
import { Containers } from '../../components/theme/containers';
import { Styling } from '../../components/theme/styling';
import { Images } from '../../assets/img';
import { Spacing } from '../../components/theme/spacing';

export const Profile = () => {
    const { theme } = React.useContext(ThemeContext);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1300)
    }, []);

    // const listStyling = `xsm:my-2 lg:my-3 xl:my-4`

    return (
        <>

            {
                loading ?
                    <div className={`
                    ${Styling.flexCenter}
                    relative z-20
                    `}>
                        <div className={`
                        ${Containers.glsFx} 
                        ${Containers.carouselContainer} 
                        ${Containers.mainContainer}
                        `}>
                            <motion.div
                                className={`${Styling.flexCenter} ${Containers.carouselSubContainer}`}
                                transition={{
                                    duration: 1.2,
                                    ease: "easeInOut"
                                }}
                                exit={{
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0
                                }}
                                initial={{
                                    opacity: 0,
                                    y: 0
                                }}
                            >
                                {theme === 'dark' ? (
                                    <BarLoader
                                        color={'hsl(207, 100%, 65%)'}
                                        loading={loading}
                                        size={50}
                                        speedMultiplier='0.9'
                                    />
                                ) : (
                                    <BarLoader
                                        color={'hsl(211, 100%, 18%)'}
                                        loading={loading}
                                        size={50}
                                        speedMultiplier='0.9'
                                    />
                                )}
                            </motion.div>
                        </div>
                    </div>
                    :
                    <motion.div
                        transition={{
                            duration: 0.75,
                            ease: "easeInOut"
                        }}
                        exit={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                            y: 0
                        }}
                        initial={{
                            opacity: 0,
                            y: 0
                        }}
                    >
                        <div
                            className={`
                            ${Styling.flexCenter}
                            relative z-0`}>
                            <div
                                className={`
                                    ${[Containers.glsFx, Containers.mainCarouselContainer, Containers.mainContainer]}
                                `}>
                                <div
                                    className={Containers.profileContainer}>
                                    {/* Profile Image */}
                                    <div
                                        className={`
                                        ${Styling.flexCenter}
                                        border-secondary border-opacity-20 dark:border-opacity-10 
                                        2xsm:hidden 2xsm:w-full 2xsm:pt-0 2xsm:pb-0 2xsm:px-0
                                        md:px-24 md:pb-12
                                        lg:block lg:border-r lg:w-1/2 lg:p-0 lg:py-14 lg:px-32
                                        xl:px-10 
                                        `}>
                                        <img
                                            alt="Nyokabi Kamotho"
                                            src={Images.nkImg}
                                            className="w-80 h-auto mx-auto p-4 rounded-[21px] overflow-hidden"
                                        />
                                    </div>

                                    {/* Info */}
                                    <div className={`
                                    ${Styling.flexY}
                                    h-auto flex-col 
                                    2xsm:w-full 2xsm:py-0 2xsm:px-0
                                    md:px-10 md:py-4 
                                    lg:py-4 
                                    xl:pr-0 xl:pl-16 `}>
                                        <div
                                            className={`
                                            ${Styling.flexCenter}
                                            border-secondary border-opacity-20 dark:border-opacity-10 
                                            2xsm:block 2xsm:w-full 2xsm:pt-0 2xsm:px-14 2xsm:pb-5
                                            md:pb-12 md:px-24 
                                            lg:hidden lg:border-r lg:w-1/2 lg:p-0 lg:py-12 lg:px-32
                                            xl:px-16
                                            `}>
                                            <img src={Images.nkImg} alt="Conflict Resolution" className="2xsm:w-full md:w-80 h-auto mx-auto border-4 rounded-lg overflow-hidden border-secondary border-opacity-20 dark:border-primary" />
                                        </div>
                                        <h2 className={`${Fonts.sectionTitleFont}`}>
                                            Nyokabi Kamotho ESQ.
                                        </h2>
                                        <p className={`${Fonts.bodyFont}`}>
                                            <ul className='list-disc ml-4'>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Advocate of the High Court of Kenya
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Accredited Mediator under the Judiciary Pilot Program
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Adjunct Lecturer (ADR, Family Law, Succession)
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Notary Public
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Commissioner of Oaths
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Chartered Institute of Arbitrators
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Certified Professional Mediator
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Chartered Mediator
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Negotiator (Includes Facilitated Dialogue)
                                                </li>
                                                <li className={`${Spacing.listStyling}`}>
                                                    Trainer: Conflict Resolution
                                                </li>
                                            </ul>
                                            <br />
                                            <p className={`${Fonts.bodyFont}`}>
                                                A quality-oriented professional emanating out of an ambitious lawyer with a tenacious, proactive approach and a genuine interest and understanding of the law with the skill to utilise the organisational, and communication skills developed through practice and involvement in the community. Expertise developed in commercial and corporate matters through practice and a vastly experienced Dispute Resolution practitioner. A keen planner and implementer with proficiency in handling administrative activities and skilled in developing curriculum. A drive to maintain a position in academia and society that will utilize my dedication and passion for Alternative Dispute Resolution.
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
            }
            {/* </motion.div> */}
        </>
    )
}