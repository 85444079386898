import React from 'react';
import { ThemeContext } from './themeContext';
import { Styling } from '../theme/styling';

// "text-gray-500 dark:text-gray-400 bg-white shadow-none p-2 focus:outline-none text-lg rounded-full outline-none ring-transparent cursor-pointer"

export const Toggle = () => {
    const { theme, setTheme } = React.useContext(ThemeContext);

    const modeBtn =
        `
            bg-gradient-to-br 
            2xsm:w-4 2xsm:h-4
            md:w-4 md:h-4 
            lg:w-4 lg:h-4 
            2xl:w-4.5 2xl:h-4.5 
        `
        ;

    const btnWindow =
        `
            w-full 
            2xsm:px-1.5
            md:p-[0.1rem]
            xl:p-[0.125rem]
            2xl:p-[0.015rem]

        `
        ;


    return (
        <>
            <div className='md:absolute md:right-0 flex flex-row justify-end z-40 text-darkPrimary/90 hover:text-darkerPrimary dark:text-lightSecondary dark:hover:text-blue-50 md:mr-4 opacity-90'>
                <div
                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                    className={`${Styling.transitionStyleFast} darkModeToggle cursor-pointer transition-all duration-500 flex flex-row bg-lightSky bg-no-repeat bg-cover dark:bg-darkSky justify-center 2xsm:px-2 md:px-5 2xl:px-7 2xsm:py-0.5 md:py-0 2xl:py-0.5 rounded-full dark:from-slate-900 dark:to-blue-800 shadow-sm 2xsm:border-2 md:border-[0.15rem] xl:border-[0.2rem] border-white hover:border-darkishPrimary/30 dark:border-darkSecondary/60 dark:hover:border-white border-opacity-90 dark:border-opacity-80`}>
                    {theme === 'dark' ? (
                        // Dark Mode
                        <button
                            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                            className={theme === 'dark' ? `${Styling.transitionStyleFast} ${btnWindow} 2xsm:translate-x-3 md:translate-x-5 2xl:translate-x-6 text-lg rounded-full ring-transparent cursor-pointer` : ''}
                        >
                            <div className={`${modeBtn} from-gray-50 via-gray-300 to-gray-700 rounded-full 2xsm:shadow-tglSm md:shadow-none xlPlus:shadow-tgl`}></div>
                            {/* <p className='text-gray-500'>Light Mode</p> */}
                        </button>

                    ) : (
                        // Light Mode
                        <button
                            onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                            className={theme === 'dark' ? '' : `${Styling.transitionStyleFast} ${btnWindow} 2xsm:-translate-x-3 md:-translate-x-5 2xl:-translate-x-6 text-lg rounded-full cursor-pointer`}
                        >
                            <div className={`${modeBtn} from-yellow-300 from-0% via-yellow-400 via-80% to-amber-600 to-90% rounded-full 2xsm:shadow-tglSm md:shadow-none xlPlus:shadow-tgl`}></div>
                        </button>
                    )}
                    <div className="md:absolute z-40 2xsm:hidden md:block w-full bg-gray-300">
                        {theme === 'dark' ? (
                            <p
                                onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                                className={(`${Styling.transitionStyleMedium} ${Styling.modeLabelStyle} `)}>
                                Dark Mode
                            </p>
                        ) : (
                            <p
                                onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                                className={(`${Styling.transitionStyleMedium} ${Styling.modeLabelStyle}`)}>
                                Light Mode
                            </p>
                        )}
                    </div>
                </div>


            </div>

        </>
    );
};
