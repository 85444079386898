export const Fonts = {
    menuFont:
        `
        uppercase
        mnuFont text-primary dark:text-gray-50 hover:text-white 
        2xsm:text-[0.8rem] 
        md:text-[0.65rem] 
        xl:text-[0.725rem] 
        2xl:text-[0.8rem]
        `
    ,

    linkFont:
        `text-primary dark:text-secondary hover:text-secondary no-underline`
    ,

    titleFont:
        `
        title text-primary dark:text-secondary uppercase text-center 
        2xsm:text-[0.9rem] 2xsm:leading-4 2xsm:tracking-tight
        xsm:text-[1rem] xsm:leading-4
        md:text-lg md:leading-5 md:tracking-normal
        xl:text-md 
        2xl:text-xl   
        `
    ,

    errorFont:
        `
        title text-primary dark:text-secondary uppercase text-center 
        2xsm:text-5xl 2xsm:leading-3
        md:text-[2.5rem] md:leading-4
        xl:text-[2.75rem] 
        2xl:text-[3rem]   
        `
    ,

    sectionTitleFont:
        `
        sectionTitle flex text-primary dark:text-lightSecondary uppercase tracking-[.05em] w-full
        border-b-[0.1rem] border-secondary/40
        2xsm:text-[0.8rem] 2xsm:justify-center 2xsm:py-3 2xsm:mb-3
        xsm:text-[0.85rem] xsm:py-3 xsm:mb-4
        md:text-[.8rem] md:pt-0 md:pb-2 md:mb-5
        lg:justify-start
        xl:mb-3
        xlPlus:text-[.9rem] xlPlus:pb-3
        2xl:text-[1rem] 2xl:pb-5 2xl:mb-5  
        `
    ,

    bodyFont:
        `
        bodyText text-gray-800 dark:text-gray-200 text-justify 
        2xsm:text-[0.7rem] 2xsm:leading-[0.85rem]
        xsm:text-[0.75rem] xsm:leading-[0.95rem]
        sm:text-[0.85rem] sm:leading-[1rem]
        md:text-[0.775rem] md:leading-4
        lg:leading-4
        xl:text-[0.75rem] 
        xlPlus:leading-4.5
        2xl:text-[0.9rem] 2xl:leading-5
        `
    ,

    inputFont:
        `
        bodyText placeholder:text-gray-700 placeholder:dark:text-gray-200 placeholder:opacity-80 text-black dark:text-white
        2xsm:text-[0.85rem] 
        lg:text-[0.9rem] 
        2xl:text-[0.95rem]
        `
    ,

    footerFont:
        `
        tracking-[.05em]
        text-white uppercase
        uppercase
        2xsm:text-[0.5rem] 
        lg:text-[0.55rem] 
        2xl:text-[0.6rem]
        `
    ,

    footerAddress:
        `
        text-white uppercase
        2xsm:text-center 2xsm:leading-[1.25rem] 2xsm:text-[0.65rem] 2xsm:mt-6 2xsm:pt-0
        xsm:leading-[1.5rem]
        md:text-right md:text-[0.7rem] md:leading-5 md:mt-0 md:pt-0
        xl:text-[0.7rem] 
        2xl:text-[0.75rem] 2xl:leading-[1.45rem]
    `
    ,

    footerLocation:
        `
        uppercase
        2xsm:text-[0.6rem] 
        lg:text-[0.65rem] 
        2xl:text-[0.7rem]
        `
    ,

    footerCopy:
        `
        uppercase tracking-[.035em]
        2xsm:text-[0.55rem] 
        lg:text-[0.55rem] 
        2xl:text-[0.65rem]
        `
    ,


}
