export const Styling = {
    flexY:
        'flex items-center'
    ,

    flexX:
        'flex justify-center'
    ,

    flexCenter:
        'flex items-center justify-center'
    ,

    transitionStyle:
        'transition-all duration-[1200ms] ease-in-out'
    ,

    transitionStyleMedium:
        'transition-all duration-[900ms] ease-in-out'
    ,

    transitionStyleFast:
        'transition-all duration-[600ms] ease-in-out'
    ,

    transitionStyleFaster:
        'transition-all duration-[300ms] ease-in-out'
    ,

    transitionStyleFastest:
        'transition-all duration-[150ms] ease-in-out'
    ,

    modeLabelStyle:
        `
            mnuFont absolute cursor-pointer opacity-80 uppercase tracking-[0.05rem] text-center w-full mx-auto 2xsm:mt-10 md:mt-[1.75rem] 2xl:mt-[2.1rem] 2xsm:text-[0.5rem] md:text-text-[0.5rem] 2xl:text-[0.6rem]
        `
    ,

    inputStyle:
        `
            bg-gray-50 w-full dark:bg-black dark:bg-opacity-30 border border-gray-500 dark:border-secondary border-opacity-20 dark:border-opacity-10 rounded-[4px] 2xsm:py-3 2xl:py-3 2xsm:px-3 2xl:px-4 2xsm:mb-4 2xl:mb-4
        `
    ,

    imgStyle:
        `
            h-auto mx-auto 2xsm:w-full 2xl:w-96
        `
    ,
}