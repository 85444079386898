import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { Navbar } from './components/navbar';
import { Home } from "./pages/home";
import { About } from "./pages/about";
import { Profile } from "./pages/profile";
import { Blog } from "./pages/blog";
import { Footer } from './components/footer';
import { ErrorPage } from "./pages/404";
import { ThemeContext } from './components/darkmode/themeContext';
import Contact from "./pages/contact";
import CookieConsent from "react-cookie-consent";

function App() {
  const { theme } = React.useContext(ThemeContext);

  const cookieBtn = `
  transition-all duration-500 mnuFont rounded-[2px] right-6 uppercase
  border-b-4 border-black border-opacity-30 dark:border-opacity-40
  2xsm:text-[0.8rem] lg:text-[0.7rem] xl:text-[0.75rem] 2xl:text-[0.8rem]
  2xsm:mt-0 2xsm:w-screen 2xsm:h-14
  md:absolute md:w-auto md:px-8 md:h-11 md:mt-1 md:right-5
  2xl:right-6 2xl:h-12
  `;

  const cookieContainer = `
  flex items-center 
  2xsm:text-3xs 2xsm:py-3 2xsm:px-0
  md:py-2 md:px-4 md:text-[.875rem]
  xl:py-3
  2xl:text-[0.95rem]
  `;

  const cookieContent = `w-full bodyText place-self-start`;

  const cookieTxt = `Yes, I Agree`;

  return (
    <div className='App'>
      <BrowserRouter>
        <Helmet>
          <title>Resolyou Kenya - Conflict Resolution</title>
          <meta
            name="description"
            content="Resolyou Kenya - Conflict Resolution"
          />
          <meta
            name="keywords"
            content="Conflict Resolution, Conflict Resolution Training, Mediation Specialist, Nyokabi Kamotho, Advocate of the High Court of Kenya, Adjunct Lecturer, Notary Public, Certified Professional Mediator, Chartered Institute of Arbitrators, Certified Professional Mediator, Negotiator"
          />
          <meta
            name="author"
            content="Designed by Linchpin Kenya"
          />
        </Helmet>
        <Navbar />
        {/* <RouteToTop /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/profile" element={<Profile />} />
          {/* <Route exact path="/blog" element={<Blog />} /> */}
          <Route exact path="/contact" element={<Contact />} />
          {/* <Route exact path="/privacy" element={<Privacy />} /> */}
          <Route exact path="/404" component={ErrorPage} />
          <Route path="*" element={<ErrorPage to="/" replace />}
          />
        </Routes>
        {theme === 'dark' ? (
          <CookieConsent
            disableButtonStyles="true"
            location="bottom"
            containerClasses={`${cookieContainer}`}
            contentClasses={`${cookieContent}`}
            buttonText={`${cookieTxt}`}
            buttonClasses={`${cookieBtn} bg-darkSecondary hover:bg-primary`}
            cookieName="myAwesomeCookieName2"
            className="bodyText"
            padding={0}
            style={{ background: "hsla(215, 100%, 4%, .97)" }}
            expires={150}
            hideOnAccept="false"
          >
            We use cookies to give you the best online experience. Please let us know if you agree to the use of cookies
          </CookieConsent>
        ) : (
          <CookieConsent
            disableButtonStyles="true"
            location="bottom"
            containerClasses={`${cookieContainer}`}
            contentClasses={`${cookieContent}`}
            buttonText={`${cookieTxt}`}
            buttonClasses={`${cookieBtn} bg-secondary hover:bg-darkerSecondary`}
            cookieName="myAwesomeCookieName2"
            className="bodyText"
            padding={0}
            style={{ background: "hsla(211, 100%, 12%, 0.98)" }}
            expires={150}
            hideOnAccept="false"
          >
            We use cookies to give you the best online experience. Please let us know if you agree to the use of cookies
          </CookieConsent>
        )
        }
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
