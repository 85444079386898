const containerColors = 'bg-white bg-opacity-80 dark:bg-darkPrimary';

//504px = 31.5rem
//512px = 32rem
//464px = 29rem
//600px = 37.5rem
//656px = 41rem

const containerDimensions = `
    2xsm:w-full 
    md:w-[90%] 
    lg:w-[70%]
    xl:w-[75%]
    xlPlus:w-[65%] 
    2xl:w-[62.5%]
    2xlPlus:w-[65%] 
    3xl:w-[55%] 
    4xl:w-[50%] 
    5xl:w-[45%]
`;

const carouselDimensions = `
    w-full 
    2xsm:h-[32rem] 
    xsm:h-[31.5rem] 
    md:h-[41rem] 
    lg:h-[29rem]
    xl:h 
    2xl:h-[37.55rem]
`;

const blogDimensions = `
    w-full 
    2xsm:h-[512px] 
    xsm:h-[504px] 
    md:h-[656px] 
    lg:h-[464px] 
    2xl:h-[1200px]
`;

//504px = 31.5rem
//512px = 32rem
//464px = 29rem
//600px = 37.5rem
//656px = 41rem


export const Containers = {
    glsFx:
        `
        gls flex h-auto rounded-lg  
        shadow-2xl dark:shadow-none 
        bg-gray-100 dark:bg-primary 
        2xsm:bg-opacity-0 2xsm:dark:bg-opacity-0
        sm:bg-opacity-30 sm:dark:bg-opacity-10
        `
    ,

    mainContainer:
        `   
        ${containerDimensions}    
        2xsm:w-full 2xsm:p-0 2xsm:py-0 
        xl:px-0 
        2xl:p-0
        `
    ,

    mainCarouselContainer:
        `   
        ${containerDimensions}
        relative flex items-center justify-center z-20 mb-12
        2xsm:mt-0 
        md:mt-6 
        xl:mt-0
        `
    ,

    introContainer:
        `   
        ${containerColors} ${carouselDimensions} 
        flex flex-col items-center rounded-lg my-auto 
        `
    ,

    carouselSubContainer:
        `
        ${containerColors} ${carouselDimensions} 
        flex items-center rounded-lg my-auto
        2xsm:flex-col 
        lg:flex-row
        `
    ,

    blogSubContainer:
        `
        ${blogDimensions} 
        relative flex items-center justify-center z-20 mb-12
        2xsm:mt-0 
        md:mt-6 
        xl:mt-0
        bg-white bg-opacity-80
        flex items-center rounded-lg my-auto
        2xsm:flex-col 
        lg:flex-row
        `
    ,

    profileContainer:
        `
        ${containerColors} 
        flex flex-row items-center rounded-lg my-auto w-full 
        2xsm:px-8 2xsm:h-auto 2xsm:py-12 
        md:px-20 
        lg:h-auto 
        `
    ,

    imageContainer:
        `   
        flex justify-center items-center 
        border-secondary border-opacity-20 dark:border-opacity-10 
        2xsm:hidden 
        md:block md:w-full md:py-8 
        lg:border-r lg:w-1/2 
        xl:p-6  
    `,

    logoSize: `
    mx-auto mb-6 opacity-100 dark:opacity-80
    2xsm:w-44 
    md:w-52
    2xl:w-60

    `,

    errorContainer:
        `
        ${containerColors} 
        flex flex-col items-center justify-center rounded-lg w-full h-115 my-auto 
             
        `
    ,

    formContainer:
        ` 
        ${containerColors} 
        flex items-center rounded-lg h-auto w-full my-auto
        2xsm:flex-col 2xsm:py-0
        md:py-4
        lg:flex-row lg:px-0
        `
    ,

    formSubContainer:
        `
        flex flex-col justify-center items-center 
        lg:border-r border-secondary border-opacity-20 dark:border-opacity-10 
        2xsm:w-full 2xsm:pt-1 
        md:pt-4 
        lg:w-1/2 lg:p-4
        `
    ,

    buttonContainer:
        `
        relative z-40 cursor-pointer 
        w-32 h-32 
        mx-4 mb-1 
        py-3  
        `
    ,
}