import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Turn as Hamburger } from 'hamburger-react'
import { createPopper } from "@popperjs/core";
import { BsFillCaretDownFill } from "react-icons/bs";
// import { HashLink } from 'react-router-hash-link';

import { ThemeContext } from '../darkmode/themeContext';
import { Fonts } from '../theme/fonts';
import { Containers } from './../theme/containers';
// import { Spacing } from '../theme/spacing';
import { Colors } from "../theme/colors";
import { Images } from '../../assets/img';
import { Toggle } from '../darkmode/toggle';
import { Styling } from '../theme/styling';


export const Navbar = () => {
    const ptnStyle = `${Styling.transitionStyleFastest} fixed w-full h-full z-0`;

    /* const logoSizes = [20, 17.5, 37.5, 48];

    const logoWidth = `2xsm:w-[${logoSizes[0]}%] lg:w-[${logoSizes[1]}%] xl:w-[${logoSizes[2]}%] 2xl:w-[${logoSizes[3]}%]`;

    const menuMath = [(100 - logoSizes[0]) / 2, (100 - logoSizes[1]) / 2, (100 - logoSizes[2]) / 2, (100 - logoSizes[3]) / 2];

    const menuHalves = `h-full inline-flex items-center justify-center border-y 2xsm:w-[${menuMath[0]}%] lg:w-[${menuMath[1]}%] xl:w-[${menuMath[2]}%] 2xl:w-[${menuMath[3]}%]` */

    const mobileSubStyle = `flex justify-start items-center text-left 2xsm:py-6 xsm:py-7 pl-4 pr-10`;

    const mobileSubBorder = `hover:bg-secondary border-b border-secondary border-opacity-20`;

    const mobileMenuTxt = `text-[0.75rem]`

    const menuActiveStyle = `flex items-center justify-center text-secondary dark:text-lightSecondary border-b-[2px] hover:bg-darkSecondary/80 hover:text-white border-darkSecondary/80 dark:border-darkSecondary/70 h-[calc(100%+4px)]`;

    const menuInactiveStyle = `w-full h-full flex items-center justify-center hover:text-white dark:hover:text-white border-b-[1.5px] border-secondary/0`;

    const subMenuStyling = `${Styling.transitionStyleFaster} hover:bg-darkSecondary dark:hover:bg-darkPrimary/80 w-full h-full flex items-center justify-center py-6`

    const navbarGlowActive = `w-full h-[3px] bg-secondary/0 dark:bg-lightSecondary/40 absolute bottom-0 blur-[0px] dark:blur-[16px]`;

    const navbarGlowInactive = `w-full h-[3px] bg-lightSecondary/0 absolute bottom-0 blur-[16px]`;


    const { theme, setTheme } = React.useContext(ThemeContext);

    const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
    const btnDropdownRef = React.createRef();
    const popoverDropdownRef = React.createRef();
    const mobileMenuBtn = useRef(null);
    const openDropdownPopover = () => {
        createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
            placement: "center"
        });
        setDropdownPopoverShow(true);
    };

    const closeDropdownPopover = () => {
        setDropdownPopoverShow(false);
    };

    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        function handleOutsideClick(event) {
            if (mobileMenuBtn.current && !mobileMenuBtn.current.contains(event.target)) {
                setOpen(false)
                setDropdownPopoverShow(false)
            }
        }
        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick)
    }, [mobileMenuBtn]);

    const [pubMenu, setPubMenu] = useState(false);
    const [pubMenuSm, setPubMenuSm] = useState(false);
    const pubMenuRef = useRef();
    const pubMenuSmRef = useRef();

    const outsidePub = (e) => {
        if (pubMenuRef.current && pubMenu && !pubMenuRef.current.contains(e.target)) {
            setPubMenu(false)
        }
        if (pubMenuSmRef.current && pubMenu && !pubMenuSmRef.current.contains(e.target)) {
            setPubMenuSm(false)
        }
    };
    document.addEventListener('mousedown', outsidePub)



    return (
        <>
            {theme === 'dark' ? (
                <div className={`${ptnStyle} ptn_drk`}></div>
            ) : (
                <div className={`${ptnStyle} ptn`}></div>
            )}
            <div className={`
                        ${Containers.transitionStyleFast} 
                        ${Styling.flexCenter}
                        w-full 
                        `}>
                <NavLink
                    to="/"
                >
                    <img
                        alt="ResolYou"
                        src={Images.logoMin}
                        className={`
                        2xsm:hidden
                        md:block
                        opacity-[99.5%] dark:opacity-90
                        lg:my-3
                        2xl:my-5
                        2xsm:w-[4rem] 
                        lg:w-[3.5rem] 
                        xl:w-[3.75rem] 
                        2xl:w-[4.5rem]
                        `} />
                </NavLink>
            </div>
            <div className="absolute right-4 top-6 flex flex-col justify-center 2xsm:hidden md:block">
                <Toggle />
            </div>
            <div className={`
            relative inline-flex justify-center w-full z-10
            2xsm:pt-1 2xsm:pb-0
            xsm:pt-1 xsm:pb-2
            md:pb-6
            xl:pb-8
            2xl:pt-0 2xl:pb-10
            `}>

                <nav className={`
                ${Styling.flexY}
                ${Containers.mainContainer}
                2xsm:hidden 2xsm:h-12
                md:block md:h-15 
                lg:h-16 
                2xl:h-18
                `}>
                    <ul className={`
                    ${Styling.transitionStyleFast} 
                    ${Colors.menuItemColor}
                    ${Colors.borderNavColor}
                    border rounded-[4px] 
                    cursor-default grid grid-flow-col auto-cols-[minmax(0,_2fr)] gap-0 items-center justify-center list-none w-full h-full text-white 
                    2xsm:text-sm 
                    lg:text-base 
                    2xl:text-md
                    `}>

                        <li
                            className={`
                            ${Fonts.menuFont} 
                            ${Styling.transitionStyleFaster}
                            h-full relative flex items-center justify-center hover:bg-darkSecondary hover:text-white border-[2px] border-secondary/0 rounded-l-[4px]
                            `}>
                            <NavLink
                                to="/"
                                className={({ isActive }) => (isActive ? `${Styling.transitionStyleFaster} dark:hover:text-white ${Styling.transitionStyleFaster} dark:hover:text-white ${menuActiveStyle} w-full` : `${Styling.transitionStyleFaster} ${menuInactiveStyle}`)}>
                                Home
                                <hr className='absolute top-[25%] h-[50%] -right-[2px] border-r-[1.5px] border-secondary/20' />
                            </NavLink>
                            <NavLink to="/" className={({ isActive }) => (isActive ? `${navbarGlowActive}` : `${navbarGlowInactive}`)} />
                            {/* <hr className='w-full h-[3px] bg-lightSecondary/60 absolute bottom-0 blur-[10px]' /> */}
                        </li>

                        {/* <hr className={`h-[60%] border border-primary/15 dark:border-secondary/15`} /> */}
                        {/* <li className={`${Spacing.menuItemBorder}`}></li> */}
                        <li
                            className={`
                            ${Fonts.menuFont} 
                            ${Styling.transitionStyleFaster}
                            h-full relative flex items-center justify-center border-[2px] hover:bg-darkSecondary hover:text-white border-secondary/0 hover:border-darkSecondary/80 dark:hover:border-secondary/40
                            `}>
                            <NavLink
                                to="/about"
                                className={({ isActive }) => (isActive ? `${Styling.transitionStyleFastest} hover:text-white dark:hover:text-white ${menuActiveStyle} w-full` : `${Styling.transitionStyleFastest} ${menuInactiveStyle}`)}>
                                About Us
                                <hr className='absolute top-[25%] h-[50%] -right-[2px] border-r-[1.5px] border-secondary/20' />
                            </NavLink>
                            <NavLink to="/about" className={({ isActive }) => (isActive ? `${navbarGlowActive}` : `${navbarGlowInactive}`)} />
                        </li>

                        <li
                            className={`
                            ${Fonts.menuFont} 
                            ${Styling.transitionStyleFaster}
                            h-full relative flex items-center justify-center border-[2px] hover:bg-darkSecondary hover:text-white border-secondary/0 hover:border-darkSecondary/80 dark:hover:border-secondary/40
                            `}>
                            <NavLink
                                to="/profile"
                                className={({ isActive }) => (isActive ? `${Styling.transitionStyleFastest} hover:text-white dark:hover:text-white ${menuActiveStyle} w-full` : `${Styling.transitionStyleFastest} ${menuInactiveStyle}`)}>
                                Profiles
                                <hr className='absolute top-[25%] h-[50%] -right-[2px] border-r-[1.5px] border-secondary/20' />
                            </NavLink>
                            <NavLink to="/profile" className={({ isActive }) => (isActive ? `${navbarGlowActive}` : `${navbarGlowInactive}`)} />
                        </li>

                        {/* <hr className={`h-[60%] border border-primary/15 dark:border-secondary/15`} /> */}
                        {/* <li className={`${Spacing.menuItemBorder}`}></li> */}
                        <li
                            className={`
                            ${Fonts.menuFont} 
                            ${Styling.transitionStyleFaster}
                            h-full relative flex items-center justify-center border-[2px] hover:bg-darkSecondary hover:text-white border-secondary/0 hover:border-darkSecondary/80 dark:hover:border-secondary/40
                            `}>
                            <NavLink
                                to="/clients"
                                className={({ isActive }) => (isActive ? `${Styling.transitionStyleFastest} hover:text-white dark:hover:text-white ${menuActiveStyle} w-full` : `${Styling.transitionStyleFastest} w-full h-full flex items-center justify-center hover:text-white dark:hover:text-white border-b-[1.5px] border-secondary/0`)}>
                                Clients
                                <hr className='absolute top-[25%] h-[50%] -right-[2px] border-r-[1.5px] border-secondary/20' />
                            </NavLink>
                            <NavLink to="/clients" className={({ isActive }) => (isActive ? `${navbarGlowActive}` : `${navbarGlowInactive}`)} />
                        </li>

                        {/* <hr className={`h-[60%] border border-primary/15 dark:border-secondary/15`} /> */}
                        <li
                            className={`
                            ${Fonts.menuFont} 
                            ${Styling.transitionStyleFaster}
                             h-full relative flex items-center justify-center border-[2px] hover:bg-darkSecondary hover:text-white border-secondary/0 hover:border-darkSecondary/80 dark:hover:border-secondary/40
                            `}>
                            <NavLink
                                to="https://resolyou.org/blog/"
                                target='blank()'
                                className={({ isActive }) => (isActive ? `${Styling.transitionStyleFastest} hover:text-white dark:hover:text-white ${menuActiveStyle} w-full` : `${Styling.transitionStyleFastest} ${menuInactiveStyle}`)}>
                                Blog
                                <hr className='absolute top-[25%] h-[50%] -right-[2px] border-r-[1.5px] border-secondary/20' />
                            </NavLink>
                            <NavLink to="/blog" className={({ isActive }) => (isActive ? `${navbarGlowActive}` : `${navbarGlowInactive}`)} />
                        </li>

                        {/* <hr className={`h-[60%] border border-primary/15 dark:border-secondary/15`} /> */}
                        {/* <li className={`${Spacing.menuItemBorder}`}></li> */}
                        <li
                            ref={pubMenuRef}
                            onClick={() => { setPubMenu(current => !current) }}
                            className={`
                            ${Fonts.menuFont} 
                            ${Styling.transitionStyleFaster}
                             h-full relative flex items-center justify-center z-40 border-[2px] hover:bg-darkSecondary hover:text-white border-secondary/0 hover:border-darkSecondary/80 dark:hover:border-secondary/40
                            `}>
                            <NavLink
                                className={`${menuInactiveStyle} hover:text-white`}>
                                <span className='-ml-1 hover:text-white'>
                                    Publications
                                </span>
                                <BsFillCaretDownFill className={`${Styling.transitionStyleMedium} absolute my-auto md:right-[0.45rem] xl:right-[0.5rem] xlPlus:right-[0.65rem] 2xl:right-[0.8rem] 3xl:right-[0.85rem] w-2.5 ${pubMenu ? 'rotate-[540deg] opacity-100' : 'rotate-0 opacity-90'}`} />
                                <hr className='absolute top-[25%] h-[50%] -right-[2px] border-r-[1.5px] border-secondary/20' />
                            </NavLink>
                            {/* <NavLink to="/profile" className={({ isActive }) => (isActive ? `${navbarGlowActive}` : `${navbarGlowInactive}`)} /> */}
                            <ul className={`${Styling.transitionStyleFaster} absolute -z-10 flex-row items-center justify-center w-full text-white bg-darkPrimary/95 dark:bg-darkSecondary/80 rounded-b-[4px] ${pubMenu ? 'visible top-[107.5%] opacity-100' : 'invisible top-[45%] opacity-0'}`}>
                                <li
                                    className={`${Styling.transitionStyleFastest} hover:text-white`}
                                >
                                    <NavLink
                                        to="/ebooks"
                                        className={({ isActive }) => (isActive ? `${subMenuStyling} border-b-[2px] border-darkSecondary/40 dark:border-darkPrimary/60` : `${subMenuStyling} border-b-[2px] border-darkSecondary/40 dark:border-darkPrimary/60`)}
                                    >
                                        E-Books
                                    </NavLink>
                                </li>
                                <li
                                    className={`${Styling.transitionStyleFastest} hover:text-white`}
                                >
                                    <NavLink
                                        to="/pubs"
                                        className={({ isActive }) => (isActive ? `${subMenuStyling} rounded-b-[4px]` : `${subMenuStyling} rounded-b-[4px]`)}
                                    >
                                        Publications
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        {/* <hr className={`h-[60%] border border-primary/15 dark:border-secondary/15`} /> */}
                        <li className={`
                            ${Fonts.menuFont} 
                            ${Styling.transitionStyleFaster}
                             h-full relative flex items-center justify-center border-[2px] hover:bg-darkSecondary hover:text-white border-secondary/0 hover:border-darkSecondary/80 dark:hover:border-secondary/40 rounded-r-[4px]
                            `}>
                            <NavLink
                                to="/contact"
                                className={({ isActive }) => (isActive ? `${Styling.transitionStyleFastest} hover:text-white dark:hover:text-white ${menuActiveStyle} w-full` : `${Styling.transitionStyleFastest} ${menuInactiveStyle}`)}>
                                Contact
                            </NavLink>
                            <NavLink to="/contact" className={({ isActive }) => (isActive ? `${navbarGlowActive}` : `${navbarGlowInactive}`)} />
                        </li>
                    </ul>

                </nav>

                {/* mobile_menu */}
                <nav
                    ref={mobileMenuBtn}
                    className={`
                    ${Containers.mainContainer} 
                    ${Styling.flexY}
                    mobileMenu relative z-[999] p-0 mb-10
                    2xsm:block 
                    2xsm:h-14 
                    xsm:h-18 
                    md:hidden 
                    md:h-16 
                    2xl:h-18 
                `}>
                    <div className={`${Styling.transitionStyleFast} ${Colors.menuItemColor} flex flex-row h-full 2xsm:w-full cursor-default `}>
                        <div className="flex items-center w-[40%] relative z-[999] border-y border-primary dark:border-secondary border-opacity-10 dark:border-opacity-15">
                            <button
                                className={`
                                    flex flex-row items-center w-auto
                                    text-white font-bold uppercase text-sm 
                                    outline-none focus:outline-none ease-linear  
                                    transition-all duration-150
                                `}
                                type="button"
                                ref={btnDropdownRef}
                                onClick={() => {
                                    dropdownPopoverShow
                                        ? closeDropdownPopover()
                                        : openDropdownPopover();
                                }}
                            >
                                {/* <h1
                                onClick={() => setOpen()}
                            >Menu</h1> */}
                                {theme === 'dark' ? (
                                    <Hamburger
                                        toggle={setOpen}
                                        rounded={true}
                                        size={24}
                                        easing="ease-in"
                                        distance="lg"
                                        duration={0.3}
                                        toggled={isOpen}
                                        color="hsl(207, 100%, 65%)"

                                    />
                                ) : (
                                    <Hamburger
                                        toggle={setOpen}
                                        rounded={true}
                                        size={24}
                                        easing="ease-in"
                                        distance="lg"
                                        duration={0.3}
                                        toggled={isOpen}
                                        color="hsl(211, 100%, 18%)"
                                    />
                                )}

                            </button>
                            <p
                                onClick={() => {
                                    dropdownPopoverShow
                                        ? closeDropdownPopover()
                                        : openDropdownPopover();
                                    setOpen(!isOpen);
                                }}
                                className={`
                                ${mobileMenuTxt}
                                mnuFont flex items-center pl-1 cursor-default 
                                text-primary dark:text-secondary uppercase 
                                h-full w-full text-left `}>
                                Menu
                            </p>
                            <div className="mnuFont uppercase text-white absolute 2xsm:top-[3.5rem] xsm:top-[4.5rem] left-0">
                                <ul
                                    ref={popoverDropdownRef}
                                    className={
                                        (dropdownPopoverShow ? "block " : "hidden ") +
                                        `${Styling.transitionStyleFaster} 
                                        ${mobileMenuTxt}
                                        bg-primary/[0.99] dark:bg-black/95 z-50 list-none text-left text-[0.75rem] rounded-br-lg shadow-2xl w-[11.5rem]`
                                    }
                                >
                                    <li>
                                        <NavLink
                                            to="/"
                                            onClick={() => {
                                                closeDropdownPopover();
                                                setOpen();

                                            }}
                                            className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle} ${mobileSubBorder}`}>
                                            Home
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/about"
                                            onClick={() => {
                                                closeDropdownPopover();
                                                setOpen();

                                            }}
                                            className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle} ${mobileSubBorder}`}>
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/profile"
                                            onClick={() => {
                                                closeDropdownPopover();
                                                setOpen();

                                            }}
                                            className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle} ${mobileSubBorder}`}>
                                            Profiles
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/clients"
                                            onClick={() => {
                                                closeDropdownPopover();
                                                setOpen();

                                            }}
                                            className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle} ${mobileSubBorder}`}>
                                            Clients
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/blog"
                                            onClick={() => {
                                                closeDropdownPopover();
                                                setOpen();

                                            }}
                                            className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle} ${mobileSubBorder}`}>
                                            Blog
                                        </NavLink>
                                    </li>
                                    <li
                                        ref={pubMenuSmRef}
                                        onClick={() => { setPubMenuSm(current => !current) }}
                                    >
                                        <NavLink
                                            // onClick={() => {
                                            //     // closeDropdownPopover();
                                            //     setOpen();
                                            // }}
                                            className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle} ${mobileSubBorder}`}>
                                            <span className='-ml-1'>
                                                Publications
                                            </span>
                                            <BsFillCaretDownFill className={`${Styling.transitionStyleMedium} absolute my-auto right-[1.25rem] w-2.5 ${pubMenuSm ? 'rotate-[540deg] opacity-100' : 'rotate-0 opacity-90'}`} />
                                        </NavLink>
                                    </li>
                                    <ul
                                        className={`${Styling.transitionStyleFaster} relative z-[999] flex-row items-center justify-center w-full text-white bg-darkPrimary/95 dark:bg-darkSecondary/80 border-b-[1.5px] border-x-[1.5px] border-darkishPrimary/70 hover:border-darkSecondary/80 rounded-b-[4px] px-4 ${pubMenuSm ? 'visible opacity-100 h-auto' : 'invisible opacity-0 h-0'}`}
                                    >
                                        <li>
                                            <NavLink
                                                to="/ebooks"
                                                onClick={() => {
                                                    closeDropdownPopover();
                                                    setOpen();
                                                    setPubMenuSm(false);
                                                }}
                                                className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} border-b-[2px] border-darkSecondary dark:border-darkPrimary/60 ${mobileSubStyle}`}>
                                                E-Books
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/pubs"
                                                onClick={() => {
                                                    closeDropdownPopover();
                                                    setOpen();
                                                    setPubMenuSm(false);
                                                }}
                                                className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle}`}>
                                                Publications
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <li>
                                        <NavLink
                                            to="/contact"
                                            onClick={() => {
                                                closeDropdownPopover();
                                                setOpen();

                                            }}
                                            className={({ isActive }) => (isActive ? "text-secondary" : `hover:text-secondary`) + `${Styling.transitionStyleFaster} ${mobileSubStyle}`}>
                                            Contact
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={`flex items-center justify-center 2xsm:w-[27.5%] xsm:w-[20%] px-4 h-full`}>
                            <NavLink
                                to="/"
                                onClick={() => {
                                    closeDropdownPopover();
                                    setOpen();
                                }}
                            >
                                <img src={Images.logoMin} alt="ResolYou" className='2xsm:w-[50px] xsm:w-[60px] lg:w-[70px]' />
                            </NavLink>
                        </div>

                        {/* dark_mode_switch */}
                        <div className={`
                        flex flex-row items-center justify-end h-full pr-3
                        border-y border-primary dark:border-secondary border-opacity-10 dark:border-opacity-15 2xsm:w-[40%]
                        `}>
                            {theme === 'dark' ? (
                                <p
                                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                                    className={`
                                        ${mobileMenuTxt}
                                        mnuFont flex items-center justify-end uppercase text-primary dark:text-secondary text-[0.75rem] h-full w-full text-right pr-3
                                        `}>
                                    Dark
                                </p>
                            ) : (
                                <p
                                    onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                                    className={`
                                    ${mobileMenuTxt}
                                    mnuFont flex items-center justify-end uppercase text-primary dark:text-secondary text-[0.75rem] h-full w-full text-right pr-3
                                    `}>
                                    Light
                                </p>
                            )}
                            {/* <p
                                onClick={() => {
                                    closeDropdownPopover();
                                    setOpen();
                                }}
                                className='mnuFont flex items-center justify-end uppercase text-primary dark:text-secondary text-xs h-full w-full text-right pr-2'>
                                Light/Dark
                            </p> */}
                            <div className="flex flex-row items-center md:pt-3.5 h-full">
                                <Toggle />
                            </div>
                        </div>
                    </div>

                </nav>
            </div>

        </>
    )
}