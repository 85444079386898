import React, { ChangeEvent, FormEvent, useState, useRef, useEffect } from 'react';
import { BarLoader } from 'react-spinners';

import emailjs from 'emailjs-com';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { ThemeContext } from '../../components/darkmode/themeContext';
import { Fonts } from '../theme/fonts';
import { Buttons } from "../theme/buttons";
import { Styling } from '../theme/styling';

type FormState = {
    email: string;
    name: string;
    message: string;
}

type ServiceMessage = {
    class: string;
    text: string;
}

function Form() {
    const { theme } = React.useContext(ThemeContext);
    const [token, setToken] = useState("");
    const captcha = useRef();

    const initialFormState = {
        email: '',
        name: '',
        message: '',
    };

    const hCaptchaSiteKey = "ca098857-1d8e-4bd3-9103-5b9084cf4f73";

    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [submitting, SetSubmitting] = useState<boolean>(false);
    const [message, setMessage] = useState<ServiceMessage>();
    // const [recaptchaToken, setRecaptchaToken] = useState<string>();

    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        SetSubmitting(true);
        await postSubmission();
        SetSubmitting(false);
    };

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        // setTimeout(() => {
        //     setLoading(false)
        // }, 1300)
    }, [])

    const postSubmission = async () => {
        if (token) {
            const msgDetails = {
                ...formState,
            };
            // console.log(payload);

            try {
                const result = await emailjs.send('service_markmutaigmail', 'template_fhmcxbo', msgDetails, '5xjsAiLXQwwov2AtB');
                console.log(result);
                setMessage({
                    class: 'bg-lime-600 mt-0.5',
                    text: 'Message Sent!',
                });
                setLoading(false);
            } catch (error) {
                setMessage({
                    class: 'bg-red-800 mt-0.5',
                    text: 'Message Failed. Please try again',
                });
                setLoading(false);
            }
        } else {
            setMessage({
                class: 'bg-red-800 mt-0.5',
                text: 'Message Failed. Please Try again',
            });
        }
    };

    const updateFormControl = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { id, value } = event.target;
        const formKey = id as keyof FormState;
        const updateFormState = { ...formState };
        updateFormState[formKey] = value;
        setFormState(updateFormState);
    };

    // const updateRecaptchaToken = (token: string | null) => {
    //     setRecaptchaToken(token as string);
    // };

    return (
        <>
            <h2
                className={`
                    ${Fonts.sectionTitleFont}
                `}>
                Contact
            </h2>
            <div
                className="w-full mx-auto">
                <form
                    onSubmit={submitForm}
                    className='flex flex-col mt-3'>
                    <input
                        id="name"
                        type="text"
                        placeholder="Name"
                        onChange={updateFormControl}
                        value={formState.name}
                        className={`
                        ${Styling.inputStyle} 
                        ${Fonts.inputFont}
                        `}
                        required
                    />
                    <input
                        id="email"
                        type="email"
                        placeholder="Email"
                        onChange={updateFormControl}
                        value={formState.email}
                        className={`
                        ${Styling.inputStyle} 
                        ${Fonts.inputFont}
                        `}
                        required
                    />
                    <textarea
                        onChange={updateFormControl}
                        id="message"
                        placeholder='Message'
                        value={formState.message}
                        className={`
                            h-48 resize-none
                            ${Fonts.inputFont} 
                            ${Styling.inputStyle} 
                        `}
                        required
                    />
                    <div className={`${Styling.flexX} w-full`}>
                        {theme === 'dark' ? (
                            // @ts-nocheck
                            <div className="opacity-80">
                                <HCaptcha
                                    ref={captcha}
                                    sitekey={hCaptchaSiteKey}
                                    onVerify={setToken}
                                    theme='dark'
                                    onExpire={e => setToken("")}
                                />
                            </div>
                        ) : (
                            <HCaptcha
                                ref={captcha}
                                sitekey={hCaptchaSiteKey}
                                onVerify={setToken}
                                theme='light'
                                onExpire={e => setToken("")}
                            />
                        )}
                    </div>
                    <button
                        disabled={submitting}
                        className={`
                            px-12 mt-4 mb-2
                            ${Buttons.btnAction} 
                            ${Styling.transitionStyleFaster} 
                        `}>
                        {submitting ?
                            <div className={`${Styling.transitionStyleFaster} h-16 flex items-center justify-center opacity-90`}>
                                <BarLoader
                                    color={'hsl(220, 0%, 100%)'}
                                    loading={loading}
                                    // css={override}
                                    size={50}
                                    // @ts-nocheck
                                    speedMultiplier='0.9'
                                />
                            </div>
                            :
                            'Send'
                        }
                    </button>
                    {message && (
                        <div
                            className={`
                            ${Styling.flexCenter}
                            ${Buttons.btnMsgState}
                            ${Styling.transitionStyleFaster} 
                            ${message.class}
                            `}>
                            {message.text}
                        </div>
                    )}
                </form>
            </div>
        </>
    )
}

export default Form;