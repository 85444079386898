import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BarLoader } from 'react-spinners';
import { ThemeContext } from '../../components/darkmode/themeContext';

import { Fonts } from '../../components/theme/fonts';
import { Containers } from '../../components/theme/containers';
import { Styling } from '../../components/theme/styling';
import { Images } from '../../assets/img';
import { Spacing } from '../../components/theme/spacing';

export const About = () => {
    const { theme } = React.useContext(ThemeContext);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1300)
    }, []);

    // const listStyling = `xsm:my-2 lg:my-3 xl:my-4`

    return (
        <>

            {
                loading ?
                    <div className={`
                    ${Styling.flexCenter}
                    relative z-20
                    `}>
                        <div className={`
                        ${Containers.glsFx} 
                        ${Containers.carouselContainer} 
                        ${Containers.mainContainer}
                        `}>
                            <motion.div
                                className={`${Styling.flexCenter} ${Containers.carouselSubContainer}`}
                                transition={{
                                    duration: 1.2,
                                    ease: "easeInOut"
                                }}
                                exit={{
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1,
                                    y: 0
                                }}
                                initial={{
                                    opacity: 0,
                                    y: 0
                                }}
                            >
                                {theme === 'dark' ? (
                                    <BarLoader
                                        color={'hsl(207, 100%, 65%)'}
                                        loading={loading}
                                        size={50}
                                        speedMultiplier='0.9'
                                    />
                                ) : (
                                    <BarLoader
                                        color={'hsl(211, 100%, 18%)'}
                                        loading={loading}
                                        size={50}
                                        speedMultiplier='0.9'
                                    />
                                )}
                            </motion.div>
                        </div>
                    </div>
                    :
                    <motion.div
                        transition={{
                            duration: 0.75,
                            ease: "easeInOut"
                        }}
                        exit={{
                            opacity: 0
                        }}
                        animate={{
                            opacity: 1,
                            y: 0
                        }}
                        initial={{
                            opacity: 0,
                            y: 0
                        }}
                    >
                        <div
                            className={`
                            ${Styling.flexCenter}
                            relative z-0`}>
                            <div
                                className={`
                                    ${[Containers.glsFx, Containers.mainCarouselContainer, Containers.mainContainer]}
                                `}>
                                <div
                                    className={Containers.profileContainer}>
                                    {/* Profile Image */}
                                    <div
                                        className={`
                                        ${Styling.flexCenter}
                                        border-secondary border-opacity-20 dark:border-opacity-10 
                                        2xsm:hidden 2xsm:w-full 2xsm:pt-0 2xsm:pb-0 2xsm:px-0
                                        md:px-24 md:pb-12
                                        lg:block lg:border-r lg:w-1/2 lg:p-0 lg:py-14 lg:px-32
                                        xl:px-10 
                                        `}>
                                        {theme === 'dark' ? (
                                            <img src={Images.logoDrk} alt="ResolYou Conflict Resolution" className={`${Containers.logoSize}`} />
                                        ) : (
                                            <img src={Images.logo} alt="ResolYou Conflict Resolution" className={`${Containers.logoSize}`} />
                                        )}
                                    </div>

                                    {/* Info */}
                                    <div className={`
                                    ${Styling.flexY}
                                    h-auto flex-col 
                                    2xsm:w-full 2xsm:py-0 2xsm:px-0
                                    md:px-10 md:py-4 
                                    lg:py-14 
                                    xl:pr-0 xl:pl-16 `}>
                                        <div
                                            className={`
                                            ${Styling.flexCenter}
                                            border-secondary border-opacity-20 dark:border-opacity-10 
                                            2xsm:block 2xsm:w-full 2xsm:pt-0 2xsm:px-14 2xsm:pb-5
                                            md:pb-12 md:px-24 
                                            lg:hidden lg:border-r lg:w-1/2 lg:p-0 lg:py-12 lg:px-32
                                            xl:px-16
                                            `}>
                                            {theme === 'dark' ? (
                                                <img src={Images.logoDrk} alt="ResolYou Conflict Resolution" className={`${Containers.logoSize}`} />
                                            ) : (
                                                <img src={Images.logo} alt="ResolYou Conflict Resolution" className={`${Containers.logoSize}`} />
                                            )}
                                        </div>
                                        <h2 className={`${Fonts.sectionTitleFont}`}>
                                            About Us
                                        </h2>
                                        <p className={`${Fonts.bodyFont}`}>
                                            <p className={`${Fonts.bodyFont}`}>
                                                Welcome to our Conflict Resolution social enterprise! We are dedicated to helping individuals, organizations, and families effectively resolve conflicts and find mutually beneficial solutions.
                                                <br />
                                                <br />
                                                Our services include mediation, negotiation, facilitated dialogue, work conflict resolution, and family dispute resolution. We understand that conflict can be challenging and stressful, and we are committed to providing a safe, confidential, and supportive environment for all parties involved. Our goal is to guide you through the conflict resolution process with compassion, empathy, and expertise.
                                                <br />
                                                <br />
                                                In addition to our conflict resolution services, we offer training programs to help individuals and organizations develop the skills and knowledge necessary to address conflicts effectively. Our training programs are tailored to meet the unique needs of each client and cover a variety of topics, including conflict management, communication, negotiation, and mediation.
                                                <br />
                                                <br />
                                                We are highly trained and experienced professionals who are committed to providing exceptional service and achieving positive outcomes for our clients. We believe that every conflict has the potential for a positive resolution, and we are dedicated to helping our clients reach that resolution
                                            </p>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
            }
            {/* </motion.div> */}
        </>
    )
}