import React from 'react';

import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';
import { BsTwitter } from 'react-icons/bs';
import { IoLogoYoutube } from 'react-icons/io';
import { RiInstagramFill } from 'react-icons/ri';

import { Styling } from '../theme/styling';

const Social = () => {
    return (
        <div className="w-full py-2 2xsm:mt-2 2xsm:mb-4 lg:-mt-2 lg:mb-2 2xsm:text-[1.5rem] xsm:text-[1.75rem] xl:text-[1.25rem] 2xl:text-[1.5rem]">
            <div className={`${Styling.transitionStyleFast} flex flex-row xsm:items-center 2xsm:justify-center lg:items-start lg:justify-start 2xsm:space-x-[2.5rem] lg:space-x-[1.5rem] text-lightSecondary`}>
                <a
                    href="https://www.facebook.com/resolyou.mediate/"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Resolyou's Facebook Page"
                    className={`${Styling.transitionStyleFast} trns hover:text-darkSecondary `}
                >
                    <FaFacebookSquare />
                </a>
                <a
                    href="https://twitter.com/resol_youADR"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Resolyou's Twitter"
                    className={`${Styling.transitionStyleFast} trns hover:text-darkSecondary`}
                >
                    <FaTwitterSquare />
                </a>
                <a
                    href="https://instagram.com/resolyou_mediate"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Resolyou's Instagram"
                    className={`${Styling.transitionStyleFast} trns hover:text-darkSecondary `}
                >
                    <RiInstagramFill />
                </a>

                <a
                    href="https://youtube.com/@ResolYou"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Resolyou's YouTube Channel"
                    className={`${Styling.transitionStyleFast} trns hover:text-darkSecondary`}
                >
                    <IoLogoYoutube />
                </a>
            </div>
        </div>
    )
}

export default Social